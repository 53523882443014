ul {
  /* list-style-type: decimal; */
}
ul ul {
  list-style-type: "  -  ";
  margin-left: -10px;
}
.smallOnly {
  display: none;
}
@media (max-width: 767px) {
  .container-fluid {
    flex-direction: column !important;
  }
  .smallOnly {
    display: block;
  }
  .largeOnly {
    display: none;
  }
}
