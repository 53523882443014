#heading{
    font-size: 35px;
    font-weight: bolder;
}

@media only screen and (max-width: 767px) {
    #heading {
      font-size: 15px;
      margin-bottom: 0;
    }
  }

#title{
  font-weight: bold;
}

#captcha{
  width: 50% !important;
}

.g-recaptcha {
  transform:scale(0.77);
  transform-origin:0 0;
}

#heading1{
  margin: 0px 22px;
}

/* @media (max-width: 767px) {
  .hide-background-image {
    background-image: none !important;
  }
} */

/* @media only screen and (max-width: 767px) {
  #image123 {
    background-image: none;
  }
} */

/* #button{
  margin : 0px 25px;
} */