/* @media only screen and (max-width : 500px){
    #loginmargin{
        background-image: url(../Photos/Gurudwaras-in-India.jpg);
        background-size: cover;
        background-size: 100%;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
    }
} */


/* #loginmargin{
    background-image: url(../Photos/Gurudwaras-in-India.jpg);
    background-size: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center; */


/* body{
    background-image: url(../Photos/Screenshot2023-01-05112943.png);
    background-size: cover;
    background-size: 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
} */

@media only screen and (max-width: 767px) {
  #image123 {
    background-image: none;
  }
}

.flex-container {
  display: flex;
  flex-direction: column;
  margin-top: 52vh;
}

.content {
  flex: 1;
}


@media only screen and (max-width: 767px) {
  .flex-container {
    margin-top: 37vh;
  }
}


